<template>
  <div>
    <div class="common-header">{{ titleData[operType] }}</div>
    <el-form label-width="80px" ref="form" :model="formData" :rules="rules">
      <el-form-item label="工程名称:" prop="projectName">
        <el-input v-model="formData.projectName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="工程分支:" prop="branch">
        <el-input v-model="formData.branch" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Git地址（http）:" prop="gitUrl">
        <el-input v-model="formData.gitUrl" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Git账号:" prop="gitUsername">
        <el-input v-model="formData.gitUsername" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Git密码:" prop="gitPassword">
        <el-input v-model="formData.gitPassword" placeholder="请输入" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save" :loading="loading">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Api from '@/api/devops/codeConfig'
export default {
  props: {
    detail: { default: () => {}, type: Object },
    operType: { type: String, default: 'add' },
  },
  data() {
    return {
      formData: {},
      titleData: {
        add: '新增工程',
        edit: '修改工程',
      },
      rules: {
        projectName: [{ required: true, message: '请输入工程名称', trigger: ['blur', 'change'] }],
        branch: [{ required: true, message: '请输入工程分支', trigger: ['blur', 'change'] }],
        gitUrl: [{ required: true, message: '请输入git地址', trigger: ['blur', 'change'] }],
        gitUsername: [{ required: true, message: '请输入git账号', trigger: ['blur', 'change'] }],
        gitPassword: [{ required: true, message: '请输入git密码', trigger: ['blur', 'change'] }],
      },
      loading: false,
      timeout: null,
    }
  },
  watch: {
    detail(val) {
      this.formData = _.isEmpty(val) ? {} : _.cloneDeep(val)
      this.$refs['form'].resetFields()
    },
  },

  methods: {
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            let { engineCode, engineVersion, engineName } = this.$route.query
            let param = {
              engineCode: engineCode,
              version: engineVersion,
              engineName: engineName,
              ...this.formData,
            }
            this.loading = true
            if (this.operType === 'add') {
              await Api.addCodeConfig(param)
            } else {
              await Api.updateCodeConfig(param)
            }
            this.$msg.success('保存成功！')
            this.$emit('save')
          } finally {
            this.loading = false
          }
        }
      })
    },

    // 通过工号获取用户名称
    async getUserName(val) {
      if (!val) return
      let data = await this.$store.dispatch('GET_USER_DETAIL_BY_ACCOUNT', { account: val, useStatus: true })
      return data?.records || []
    },
    async querySearchAsync(queryString, cb) {
      if (!queryString) {
        cb([])
        return
      }
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        let data = (await this.getUserName(queryString)) || []
        data.forEach((item) => (item.value = item.account))
        cb(data)
      }, 500)
    },
    handleGroupSelect(data) {
      this.$set(this.formData, 'developLeaderName', data.name)
    },
    handleManagerSelect(data) {
      this.$set(this.formData, 'productManagerName', data.name)
    },
  },
}
</script>
<style lang="scss">
.none {
  display: none !important;
}
</style>
