<!-- 页面引擎 -->
<template>
  <div class="h100">
    <div class="h100 flex page-wrapper">
      <common-layout class="flex-1 flex-column h100 w-0">
        <template #header>
          <el-form inline @submit.native.prevent>
            <el-form-item>
              <el-input placeholder="请输入工程名称" v-model="searchForm.name" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query">查询</el-button>
              <el-button type="primary" @click="handleEvent('add')">新增</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table ref="table" :data="tableData" border highlight-current-row height="100%" v-loading="loading">
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center" min-width="150" />
            <el-table-column label="操作" align="center" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="handleEvent('edit', scope.row)">修改</el-button>
                <el-button type="text" @click="handleEvent('del', scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </common-layout>
      <w-form-box :operType="operType" :detail="curRow" @save="save" style="width: 300px" class="ml10 pl10 border-left-line h100 overflow-auto" />
    </div>
  </div>
</template>

<script>
import WFormBox from './formBox.vue'

import Api from '@/api/devops/codeConfig'

export default {
  components: { WFormBox },
  data() {
    return {
      loading: false,
      searchForm: {},

      tableData: [],
      tableHeader: [
        { code: 'projectName', name: '工程名称' },
        { code: 'projectCode', name: '工程编码' },
        { code: 'branch', name: '工程分支' },
        { code: 'createdBy', name: '创建账号' },
        { code: 'createdTime', name: '创建时间' },
        { code: 'updatedBy', name: '修改账号' },
        { code: 'updatedTime', name: '修改时间' },
      ],
      operType: 'add',
      curRow: {},
    }
  },
  created() {
    this.query()
  },
  methods: {
    async query() {
      try {
        this.loading = true
        let { engineCode, engineVersion } = this.$route.query
        const param = {
          engineCode: engineCode,
          version: engineVersion,
          ...this.searchForm,
        }
        const { data } = await Api.getCodeConfig(param)
        this.tableData = data || []
      } finally {
        this.loading = false
      }
    },

    handleEvent(type, row) {
      switch (type) {
        case 'add':
          this.operType = type
          this.curRow = {}
          break
        case 'edit':
          this.operType = type
          this.curRow = { ...row }
          break
        case 'del':
          this.$confirm(`确定删除此工程吗！`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            try {
              this.tableLoading = true
              await Api.deleteCodeConfig({ id: row.id })
              this.$msg.success('删除成功！')
              await this.query()
            } finally {
              this.tableLoading = false
            }
          })
          break
      }
    },
    save() {
      this.curRow = {}
      this.operType = 'add'
      this.query(1)
    },
  },
}
</script>
<style lang="scss" scoped></style>
