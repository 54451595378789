import http from '@/utils/axios'

const data = {
  getCodeConfig: (data) => http.get('/webapp/engine/project', data),
  addCodeConfig: (data) => http.post('/webapp/engine/project', data),
  updateCodeConfig: (data) => http.put('/webapp/engine/project', data),
  deleteCodeConfig: (data) => http.delete(`/webapp/engine/project`, data),
}

export default data
